<template>
  <div>
    <b-card
      id="pdfGenerateDiv"
      ref="pdfGenerateDivRef"
      no-body
      class="invoice-preview-card main-table soa__print--content"
    >
      <div>
        <div
          class="card__inner"
        >
          <table style="width: 100%; border-collapse: separate; border-spacing: 10px 5px;">
            <thead style="display: table-header-group;">
              <tr>
                <td>
                  <!-- Header -->
                  <b-card-body
                    id="main-header"
                    class="invoice-padding pb-0"
                  >
                    <div
                      class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
                    >
                      <div class="logo-wrapper">
                        <img
                          style="width:255px; height: 77px;"
                          src="@/assets/images/logo/sticker-logo.png"
                          alt="Logo"
                        >
                      </div>
                    </div>
                    <!-- Spacer -->
                    <div
                      class="d-flex justify-content-between invoice-spacing mt-0"
                      style="font-family: Inter;"
                    >
                      <div
                        style="min-width: fit-content; width: fit-content; margin-top: 28px;"
                      >
                        <h4
                          v-if="soaAgent"
                          style="font-family: Inter; text-align: left; color: #DC1B1B; font-weight: 600; font-size: 24px; line-height: 32px;"
                        >
                          Agent’s Copy
                        </h4>
                        <h4 style="font-family: Inter; text-align: left; color: rgba(51, 51, 51, 1); font-weight: 700; font-size: 30px; line-height: 12px;">
                          Statement of Account
                        </h4>
                        <h6 style="font-family: Inter; text-align: left; color: rgba(51, 51, 51, 1); font-weight: 600; font-size: 20px; line-height: 32px; margin-bottom: 0;">
                          As at {{ todayDate }}
                        </h6>
                      </div>

                      <div class="mt-md-0 text-right">
                        <h4
                          class="invoice-title"
                          style="font-family: Inter; text-align: right; color: rgba(51, 51, 51, 1); font-weight: 700; font-size: 26px; line-height: 32px;"
                        >
                          {{ fileDetail ? fileDetail.Name : '' }}
                        </h4>
                        <p style="font-family: Inter; text-align: right; color: rgba(51, 51, 51, 1); font-weight: 400; font-size: 14px; line-height: 18.2px; margin-bottom: 0;">
                          {{ fileDetail ? fileDetail.Cor_Addr1 : '' }}
                        </p>
                        <p style="font-family: Inter; text-align: right; color: rgba(51, 51, 51, 1); font-weight: 400; font-size: 14px; line-height: 18.2px; margin-bottom: 0;">
                          {{ fileDetail ? fileDetail.Cor_Addr2 : '' }}
                        </p>
                        <p style="font-family: Inter; text-align: right; color: rgba(51, 51, 51, 1); font-weight: 400; font-size: 14px; line-height: 18.2px; margin-bottom: 0;">
                          {{ fileDetail ? fileDetail.Cor_Addr3 : '' }}
                        </p>
                      </div>
                    </div>
                  </b-card-body>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <!-- Invoice Client & Payment Details -->
                  <b-card-body
                    class="invoice-padding main__body po__create preview_order_pdf"
                  >
                    <div
                      class="d-flex justify-content-between invoice-spacing mt-0"
                      style="gap: 10px;"
                    >
                      <div
                        class="purchase-info"
                      >
                        <div>
                          <h4 class="purchase-info__title">
                            Purchase Information
                          </h4>
                          <div class="purchase-info__single">
                            <strong class="purchase-info__single-title">
                              Product
                            </strong>
                            <span class="purchase-info__single-value">{{ nvsFileDetail ? nvsFileDetail.nvsFileType : '' }}</span>
                          </div>
                          <!-- <div class="purchase-info__single">
                            <strong class="purchase-info__single-title">
                              Package name
                            </strong>
                            <span class="purchase-info__single-value">LV Longevity</span>
                          </div> -->
                          <div class="purchase-info__single">
                            <strong class="purchase-info__single-title">
                              File no.
                            </strong>
                            <span class="purchase-info__single-value">{{ fileDetail ? fileDetail.File_No : '' }}</span>
                          </div>
                          <div class="purchase-info__single">
                            <strong class="purchase-info__single-title">
                              Purchase date
                            </strong>
                            <span class="purchase-info__single-value">{{ fileDetail ? dateFormat(fileDetail.Purchase_Date) : '' }}</span>
                          </div>
                          <div class="purchase-info__single">
                            <strong class="purchase-info__single-title">
                              Payment mode
                            </strong>
                            <span class="purchase-info__single-value">{{ (nvsFileDetail && nvsFileDetail.ledgerSummary && nvsFileDetail.ledgerSummary.paymentMethod) ? nvsFileDetail.ledgerSummary.paymentMethod : '-' }}</span>
                          </div>
                          <div
                            v-if="fileDetail && fileDetail.Tenure"
                            class="purchase-info__single"
                          >
                            <strong class="purchase-info__single-title">
                              Installment tenure
                            </strong>
                            <span class="purchase-info__single-value">{{ fileDetail ? `${fileDetail.Tenure} months` : 0 }}</span>
                          </div>
                        </div>
                      </div>

                      <div
                        class="purchase-info"
                      >
                        <div>
                          <h4 class="purchase-info__title">
                            Payment Summary
                          </h4>
                          <div class="purchase-info__single">
                            <strong class="purchase-info__single-title">
                              Purchase price
                            </strong>
                            <span class="purchase-info__single-value">${{ fileDetail ? numberFormat(fileDetail.Purchase_Price) : 0 }}</span>
                          </div>
                          <div class="purchase-info__single">
                            <strong class="purchase-info__single-title">
                              {{ fileDetail ? ((fileDetail.GST * 100) / fileDetail.Purchase_Price) : 0 }}% GST
                            </strong>
                            <span class="purchase-info__single-value">${{ fileDetail ? numberFormat(fileDetail.GST) : 0 }}</span>
                          </div>
                          <div class="purchase-info__single">
                            <strong class="purchase-info__single-title">
                              Discount
                            </strong>
                            <span class="purchase-info__single-value">(${{ fileDetail ? numberFormat(Math.abs(fileDetail.Discount)) : 0 }})</span>
                          </div>
                          <div class="purchase-info__single">
                            <strong class="purchase-info__single-title">
                              GST rebate
                            </strong>
                            <span class="purchase-info__single-value">(${{ fileDetail ? numberFormat(Math.abs(fileDetail.GST_Rebate)) : 0 }})</span>
                          </div>
                          <div
                            class="purchase-info__single"
                          >
                            <strong class="purchase-info__single-title">
                              Total payment
                            </strong>
                            <span class="purchase-info__single-value">${{ fileDetail ? numberFormat(fileDetail.Total_Credit) : 0 }}</span>
                          </div>
                          <div
                            class="purchase-info__single"
                          >
                            <strong class="purchase-info__single-title">
                              Total outstanding
                            </strong>
                            <span class="purchase-info__single-value">${{ fileDetail ? numberFormat(fileDetail.Total_Balance) : 0 }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <b-row
                      id="blue-button"
                    >
                      <b-col
                        cols="12"
                        class="item__description_table"
                      >
                        <!-- Invoice Description: Table -->
                        <b-table-simple
                          v-if="fileDetail && fileDetail.Items && fileDetail.Items.length"
                          ref="teamsTable"
                          class="position-relative has_padding soa-pdf__table"
                          responsive
                          caption-top
                        >
                          <b-thead>
                            <b-tr class="group__tag">
                              <th>Document No.</th>
                              <th width="13%">
                                Document Date
                              </th>
                              <th>Description</th>
                              <th>Cheque No.</th>
                              <th>Price</th>
                              <th>Debit</th>
                              <th>Credit</th>
                              <th>Balance</th>
                            </b-tr>
                          </b-thead>
                          <b-tbody
                            class="white-body-background"
                          >
                            <tr
                              v-for="i in fileDetail.Items"
                              :key="i.ID"
                              style="page-break-inside: avoid; page-break-after: auto;"
                            >
                              <b-td
                                style="page-break-inside: avoid; page-break-after: auto;"
                              >
                                {{ i.Doc_No }}
                              </b-td>
                              <b-td>{{ dateFormat(i.Doc_Date) }}</b-td>
                              <b-td>{{ i.Desc }}</b-td>
                              <b-td>{{ i.Cheque }}</b-td>
                              <b-td>{{ numberFormat(i.Price) }}</b-td>
                              <b-td>{{ numberFormat(i.Debit) }}</b-td>
                              <b-td>{{ numberFormat(i.Credit) }}</b-td>
                              <b-td>
                                <span
                                  v-if="i.Balance < 0"
                                  class="text-bold-black"
                                >
                                  ({{ numberFormat(Math.abs(i.Balance)) }})
                                </span>
                                <span
                                  v-else
                                  class="text-bold-black"
                                >
                                  {{ numberFormat(i.Balance) }}
                                </span>
                              </b-td>
                            </tr>
                            <tr class="td__bg-white">
                              <b-td colspan="4" />
                              <b-td class="td__border" />
                              <b-td class="td__border">
                                <span class="text-bold-black">{{ fileDetail ? numberFormat(fileDetail.Total_Debit) : 0 }}</span>
                              </b-td>
                              <b-td class="td__border">
                                <span class="text-bold-black">{{ fileDetail ? numberFormat(fileDetail.Total_Credit) : 0 }}</span>
                              </b-td>
                              <b-td class="td__border">
                                <span class="text-bold-black">{{ fileDetail ? numberFormat(fileDetail.Total_Balance) : 0 }}</span>
                              </b-td>
                            </tr>
                          </b-tbody>
                        </b-table-simple>
                        <p
                          v-else
                          class="text-center pt-3"
                        >
                          No data for the past 12 months
                        </p>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </td>
              </tr>
              <tr>
                <td class="td__bg-white">

                  <div>
                    <!-- Invoice Description: Total -->
                    <b-card-body
                      id="footer-div"
                      class="invoice-padding pb-0"
                      style="margin-bottom: 20px"
                    >
                      <p style="font-family: Inter; font-size:14px; font-weight: 400; line-height:16.94px; text-align: center; margin-bottom: 50px; color: rgba(0, 0, 0, 1);">
                        - End of Report -
                      </p>
                      <b-row class="invoice-spacing">
                        <b-col
                          class="p-0"
                        >
                          <p
                            style="font-family: Inter; font-size:14px; font-weight: 400; line-height:16.94px; text-align: left; margin:0; color: rgba(0, 0, 0, 1);"
                          >
                            (a) Whilst every care has been taken in the preparation of this statement, it is subject to correction and confers no legal rights.
                          </p>
                          <p
                            style="font-family: Inter; font-size:14px; font-weight: 400; line-height:16.94px; text-align: left; margin:0; color: rgba(0, 0, 0, 1);"
                          >
                            (b) The entries and balance shown above will be considered correct unless we are notified of any discrepancies within fourteen (14) days.
                          </p>
                          <p
                            style="font-family: Inter; font-size:14px; font-weight: 400; line-height:16.94px; text-align: left; margin:0; color: rgba(0, 0, 0, 1);"
                          >
                            (c) Please advise us promptly of any change in address.
                          </p>
                        </b-col>
                      </b-row>
                    </b-card-body>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BTableSimple, BTbody, BThead, BTr, BTd, BCardBody,
} from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTableSimple,
    BTbody,
    BThead,
    BTr,
    BTd,
    BCardBody,
  },
  data() {
    return {
      generateNow: false,
      elHeight: 0,
      gapHeight: 1,
      numberOfEl: 45,
      todayDate: moment().format('DD MMMM YYYY'),
      fileDetail: JSON.parse(localStorage.getItem('soaResponse')),
      nvsFileDetail: JSON.parse(localStorage.getItem('soaFileResponse')),
      soaAgent: JSON.parse(localStorage.getItem('soaAgent')),
      // fileDetail: {},
    }
  },
  beforeMount() {
  //   this.$http.get('app/customer/file/68959/soa')
  //     .then(response => {
  //       this.fileDetail = response.data.data
  //       localStorage.setItem('soaResponse', JSON.stringify(response.data.data))
  //     })
  //     .catch(error => {
  //       console.log(error)
  //     })
  },
}
</script>
<style lang="scss">
    body div#app.semi-dark-layout {
        background: #fff !important;
        background-repeat: no-repeat !important;
        background-position: center bottom !important;
        background-size: 100% auto !important;
    }
    body {
        background: #fff !important;
    }
    .card {
        box-shadow: none !important;
    }
    .soa-pdf__table table{
        border: none;
    }
    .soa-pdf__table table tr th{
        border-top: none;
        background: #fff !important;
        border-bottom: 2px solid rgba(102, 102, 102, 0.1);
        font-family: Inter;
        font-size: 14px !important;
        font-weight: 600;
        line-height: 16.94px;
        text-align: left;
        color: rgba(0, 0, 0, 1) !important;
        text-transform: capitalize;
    }
    .soa-pdf__table table tr td{
        border-top: none;
        font-family: Inter;
        font-size: 14px !important;
        font-weight: 400;
        line-height: 16.94px;
        text-align: left;
        color: rgba(0, 0, 0, 1) !important;
    }
    tr:nth-child(even) {
      background: rgba(102, 102, 102, 0.05);
    }
    .td__bg-white {
      background: #fff !important;
    }
    .td__border{
        border-top: 2px solid rgba(0, 0, 0, 1) !important;
        border-bottom: 2px solid rgba(0, 0, 0, 1) !important;
    }
    .purchase-info{
      width: 100%;
      margin-top: 0px;
      border: 2px solid rgba(102, 102, 102, 0.1);
      padding: 15px 40px 20px 40px;
      border-radius: 8px;
    }
    .purchase-info__title{
      font-family: Inter;
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: -0.4000000059604645px;
      text-align: left;
      color: rgba(51, 51, 51, 1);
    }
    .purchase-info__single{
        display: flex;
        justify-content: space-between;
    }
    .purchase-info__single-title{
      font-family: Inter;
      font-size: 14px;
      font-weight: 600;
      line-height: 16.94px;
      text-align: left;
    }
    .purchase-info__single-value{
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.94px;
      text-align: right;
    }
    .soa__pdf-hr{
       height: 2px;
       width: 100%;
       background:rgba(102, 102, 102, 0.1);
       margin-right: 1rem;
       margin-left: 1rem;
    }
</style>
<style>
    @media print {
      html, body {
        height: 99%;
      }

      .soa__print--content{
         padding: 0 !important;
      }
    }

    .soa__print--content{
       padding: 1rem;
    }

    @page {
        /* size: A4 portrait; */
    }
</style>
